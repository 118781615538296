import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

async function startApp() {
  // if (process.env.NODE_ENV === "development") {
  //   await initializeMockWorker();
  // }

  // async function enableMocking() {
  //   if (process.env.NODE_ENV !== "development") {
  //     return;
  //   }

  //   const { worker } = await import("./mocks/browsers");
  //   return worker.start();
  // }

  // enableMocking();

  const root = ReactDOM.createRoot(document.getElementById("root"));
  const queryClient = new QueryClient();

  root.render(
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

startApp();
