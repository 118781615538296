import axios from "axios";

const getAdminCapaList = async (prj_cd) => {
  const response = await axios.get(`/adminUser/capa/type?prj_cd=${prj_cd}`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });

  const responseData = response.data;

  return responseData;
};

export default getAdminCapaList;
