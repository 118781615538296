import Capacity from "./pages/mobile/Capacity";
import Commom from "./pages/mobile/Common";
import LoginPage from "./pages/mobile/LoginPage";

const routesMobile = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/m/common/*",
    element: <Commom />,
  },
  {
    path: "/capacity",
    element: <Capacity />,
  },
];

export default routesMobile;
