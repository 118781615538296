import { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { ImDownload, ImUpload } from "react-icons/im";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import AdminModalContainer from "../../components/pc/admin/AdminModalContainer";
import AdminQstUploadModalComp from "../../components/pc/admin/AdminQstUploadModalComp";
import { useAdminUserTabState } from "../../store/mobile/store";
import AdminUserArea from "./AdminUserArea";
import AdminUserCount from "./AdminUserCount";
import AdminUserDept from "./AdminUserDept";
import AdminUserDisp from "./AdminUserDisp";
import AdminUserEtc1 from "./AdminUserEtc1";
import AdminUserEtc2 from "./AdminUserEtc2";
import AdminUserEtc3 from "./AdminUserEtc3";
import AdminUserUser from "./AdminUserUser";
import AdminUserWork from "./AdminUserWork";

const AdminUser = () => {
  // file 업로드 모달 상태
  const [uploadModal, setUploadModal] = useState(false);
  const adminUserTab = useAdminUserTabState((state) => state.adminUserTab);
  const setAdminUserTab = useAdminUserTabState((state) => state.setAdminUserTab);
  const navigate = useNavigate();

  const handleClickDownload = () => {};

  const handleClickTab = (tab, tabNm) => {
    setAdminUserTab(tab);
    navigate(`/Admin/user/${tabNm}`);
  };

  const handleClickFileName = () => {
    const s3BucketUrl = "https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/file/";
    const tempFileNm = "유저 및 사전정보 업로드 양식.xlsx";

    window.open(s3BucketUrl + tempFileNm);
  };

  useEffect(() => {
    setAdminUserTab(0);
    navigate(`/Admin/user/disp`);

    return () => {
      setAdminUserTab(0);
    };
  }, []);

  return (
    <div className="relative flex h-full w-full flex-col border-b-2 border-r-2 border-FilterInActiveColor bg-white p-10 font-SDGothic text-[16px] font-normal text-selectTextColor/90">
      {/* 파일 업로드 모달 */}
      {uploadModal ? (
        <AdminModalContainer setModal={setUploadModal} size="T">
          <AdminQstUploadModalComp setModal={setUploadModal} fileDvs={"U"} />
        </AdminModalContainer>
      ) : null}

      {/* title */}
      <div className="mt-6 flex h-fit w-full flex-row items-center justify-start text-[20px] font-bold">
        <FaUser className="mb-2 mr-7 text-[24px] text-orangeColor" />
        대상자 정보
      </div>
      {/* file btns */}
      <div className="absolute right-10 top-10 flex h-fit w-fit flex-row items-center justify-center">
        {/* ec2서버 가동 시 수정 */}
        <Link to="#" onClick={handleClickFileName}>
          <button
            onClick={handleClickDownload}
            className="mr-5 flex h-fit w-[220px] flex-row items-center justify-center rounded-xl bg-orangeColor p-6 text-[16px] font-bold text-white hover:bg-orangeColor/80"
          >
            <ImDownload className="mb-2 mr-3 text-[20px]" />
            Excel 양식 다운로드
          </button>
        </Link>
        <button
          onClick={() => {
            document.body.style.overflow = "hidden";
            setUploadModal(true);
          }}
          className="flex h-fit w-[220px] flex-row items-center justify-center rounded-xl bg-orangeColor p-6 text-[16px] font-bold text-white hover:bg-orangeColor/80"
        >
          <ImUpload className="mb-2 mr-3 text-[20px]" />
          Excel 파일 업로드
        </button>
      </div>

      {/* tab bar */}
      <div className="mt-24 flex h-fit w-full cursor-pointer flex-row items-center justify-start font-SDGothic text-[16px] font-bold text-white">
        {/* 사전정보 조사여부 */}
        <div
          onClick={() => handleClickTab(0, "disp")}
          className={
            adminUserTab === 0
              ? `flex h-fit w-[127px] flex-col items-center justify-center rounded-t-2xl border-r border-white bg-orangeColor px-1 py-10 hover:bg-orangeColor/80`
              : `border-whit flex h-fit w-[127px] flex-col items-center justify-center rounded-t-2xl border-r border-white bg-FilterInActiveColor/60 px-1 py-10 text-selectTextColor/80 hover:bg-FilterInActiveColor/80`
          }
        >
          사전정보
        </div>
        <div
          onClick={() => handleClickTab(1, "dept")}
          className={
            adminUserTab === 1
              ? `flex h-fit w-[124px] flex-col items-center justify-center rounded-t-2xl border-r border-white bg-orangeColor px-1 py-10 hover:bg-orangeColor/80`
              : `border-whit flex h-fit w-[124px] flex-col items-center justify-center rounded-t-2xl border-r border-white bg-FilterInActiveColor/60 px-1 py-10 text-selectTextColor/80 hover:bg-FilterInActiveColor/80`
          }
        >
          방문진료과
          {/* 부서(방문진료과) */}
        </div>
        <div
          onClick={() => handleClickTab(2, "work")}
          className={
            adminUserTab === 2
              ? `flex h-fit w-[123px] flex-col items-center justify-center rounded-t-2xl border-r border-white bg-orangeColor px-1 py-10 hover:bg-orangeColor/80`
              : `border-whit flex h-fit w-[123px] flex-col items-center justify-center rounded-t-2xl border-r border-white bg-FilterInActiveColor/60 px-1 py-10 text-selectTextColor/80 hover:bg-FilterInActiveColor/80`
          }
        >
          직종상세
        </div>
        <div
          onClick={() => handleClickTab(3, "area")}
          className={
            adminUserTab === 3
              ? `flex h-fit w-[123px] flex-col items-center justify-center rounded-t-2xl border-r border-white bg-orangeColor px-14 py-10 hover:bg-orangeColor/80`
              : `border-whit flex h-fit w-[123px] flex-col items-center justify-center rounded-t-2xl border-r border-white bg-FilterInActiveColor/60 px-14 py-10 text-selectTextColor/80 hover:bg-FilterInActiveColor/80`
          }
        >
          지역
        </div>
        <div
          onClick={() => handleClickTab(4, "etc1")}
          className={
            adminUserTab === 4
              ? `flex h-fit w-[123px] flex-col items-center justify-center rounded-t-2xl border-r border-white bg-orangeColor px-14 py-10 hover:bg-orangeColor/80`
              : `border-whit flex h-fit w-[123px] flex-col items-center justify-center rounded-t-2xl border-r border-white bg-FilterInActiveColor/60 px-14 py-10 text-selectTextColor/80 hover:bg-FilterInActiveColor/80`
          }
        >
          기타1
        </div>
        <div
          onClick={() => handleClickTab(5, "etc2")}
          className={
            adminUserTab === 5
              ? `flex h-fit w-[123px] flex-col items-center justify-center rounded-t-2xl border-r border-white bg-orangeColor px-14 py-10 hover:bg-orangeColor/80`
              : `border-whit flex h-fit w-[123px] flex-col items-center justify-center rounded-t-2xl border-r border-white bg-FilterInActiveColor/60 px-14 py-10 text-selectTextColor/80 hover:bg-FilterInActiveColor/80`
          }
        >
          기타2
        </div>
        <div
          onClick={() => handleClickTab(6, "etc3")}
          className={
            adminUserTab === 6
              ? `flex h-fit w-[123px] flex-col items-center justify-center rounded-t-2xl border-r border-white bg-orangeColor px-14 py-10 hover:bg-orangeColor/80`
              : `border-whit flex h-fit w-[123px] flex-col items-center justify-center rounded-t-2xl border-r border-white bg-FilterInActiveColor/60 px-14 py-10 text-selectTextColor/80 hover:bg-FilterInActiveColor/80`
          }
        >
          기타3
        </div>
        <div
          onClick={() => handleClickTab(7, "user")}
          className={
            adminUserTab === 7
              ? `flex h-fit w-[123px] flex-col items-center justify-center rounded-t-2xl border-r border-white bg-orangeColor px-10 py-10 hover:bg-orangeColor/80`
              : `border-whit flex h-fit w-[123px] flex-col items-center justify-center rounded-t-2xl border-r border-white bg-FilterInActiveColor/60 px-10 py-10 text-selectTextColor/80 hover:bg-FilterInActiveColor/80`
          }
        >
          대상자
        </div>
        <div
          onClick={() => handleClickTab(8, "count")}
          className={
            adminUserTab === 8
              ? `flex h-fit w-[123px] flex-col items-center justify-center rounded-t-2xl border-r border-white bg-orangeColor px-10 py-10 hover:bg-orangeColor/80`
              : `border-whit flex h-fit w-[123px] flex-col items-center justify-center rounded-t-2xl border-r border-white bg-FilterInActiveColor/60 px-10 py-10 text-selectTextColor/80 hover:bg-FilterInActiveColor/80`
          }
        >
          인원수
        </div>
      </div>
      {/* routing */}
      <div className="flex h-fit w-full flex-col items-center justify-start">
        <Routes>
          <Route key="/disp" path="/disp" element={<AdminUserDisp />} />
          <Route key="/dept" path="/dept" element={<AdminUserDept />} />
          <Route key="/work" path="/work" element={<AdminUserWork />} />
          <Route key="/area" path="/area" element={<AdminUserArea />} />
          <Route key="/etc1" path="/etc1" element={<AdminUserEtc1 />} />
          <Route key="/etc2" path="/etc2" element={<AdminUserEtc2 />} />
          <Route key="/etc3" path="/etc3" element={<AdminUserEtc3 />} />
          <Route key="/user" path="/user" element={<AdminUserUser />} />
          <Route key="/count" path="/count" element={<AdminUserCount />} />
        </Routes>
      </div>
    </div>
  );
};

export default AdminUser;
