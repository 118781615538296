import postCapaValue from "../../../api/Admin/Common/postCapaValue";

import { useMutation } from "@tanstack/react-query";

export const usePostCapaValue = (prj_cd) => {
  //   const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: async (data) => await postCapaValue(data),
    onSuccess: () => {
      //   queryClient.invalidateQueries({
      //     queryKey: ["getAdminCapaList", prj_cd],
      //   });
    },
    onError: (err) => {
      console.error(err);
    },
  });

  return { mutateAsync };
};
