/**
 * 작성자: KYD
 * 기능:
 * 프로세스 설명: 프로세스 복잡시 노션링크 첨부권장
 */

import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import CapacityList from "../../components/mobile/capacity/CapacityList";
import CapaFilter from "../../components/mobile/capacity/CapaFilter";
import ErrorFallback from "../../components/mobile/ErrorFallBack";

const Capacity = () => {
  //SECTION HOOK호출 영역

  //!SECTION HOOK호출 영역

  //SECTION 상태값 영역

  //!SECTION 상태값 영역

  //SECTION 메서드 영역

  //!SECTION 메서드 영역

  return (
    <div>
      <CapaFilter />
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary onReset={reset} FallbackComponent={ErrorFallback}>
            <Suspense fallback={<>로딩중</>}>
              <CapacityList />
            </Suspense>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
      {/* <CapacityList /> */}
    </div>
  );
};

export default Capacity;
