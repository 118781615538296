import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCapaUser } from "../../hooks/User/useCapaUser";
import { useCSUser } from "../../hooks/User/useCSUser";
import { useUser } from "../../hooks/User/useUser";
import { usePeriodState, useServiceModeState, useUserDataState, useUserIdState } from "../../store/mobile/store";

const LoginPage = () => {
  const navigate = useNavigate();

  // mobileState
  const setUserId = useUserIdState((state) => state.setUserId);

  // userData
  const setUserData = useUserDataState((state) => state.setUserData);

  // serviceMode
  const serviceMode = useServiceModeState((state) => state.serviceMode);
  const setServiceMode = useServiceModeState((state) => state.setServiceMode);

  // setStPeriod
  const setStPeriod = usePeriodState((state) => state.setStPeriod);
  // setEdPeriod
  const setEdPeriod = usePeriodState((state) => state.setEdPeriod);

  const url = window.location;

  const urlParams = new URLSearchParams(url.search);

  // id State
  const [id, setId] = useState("");
  // pw State
  const [pw, setPw] = useState("");

  // id변경함수
  const handleChangeId = (e) => {
    setId(e.target.value);
  };

  // login API 호출 Hooks
  const { isUserLoading, UserData, UserRefetch, isUserSuccess, isUserFetching } = useUser(id ? id : urlParams.get("id"));
  const { isCSUserLoading, CSUserData, isCSUserSuccess, CSUserRefetch, isCSUserFetching } = useCSUser(id ? id : urlParams.get("id"));
  const { isCapaUserLoading, CapaUserData, isCapaUserSuccess, CapaUserRefetch, isCapaUserFetching } = useCapaUser(id ? id : urlParams.get("id"));
  // id에서 엔터입력 함수
  const handleIdPressEnter = (e) => {
    // Enter입력, id에 값이 있는 상태일 경우
    if (e.key === "Enter" && id) {
      document.getElementsByClassName("pw")[0].focus();
    }
  };

  // pw변경함수
  const handleChangePw = (e) => {
    setPw(e.target.value);
  };

  // pw에서 엔터입력 함수
  const handlePwPressEnter = (e) => {
    // Enter입력, pw에 값이 있는 상태일 경우
    if (e.key === "Enter" && pw && id) {
      if (serviceMode === "scan") {
        UserRefetch();
      } else if (serviceMode === "csscan") {
        CSUserRefetch();
      } else if (serviceMode === "capa") {
        CapaUserRefetch();
      }
    }
    // Enter입력, id에 값이 없는 경우
    else if (e.key === "Enter" && !id) {
      alert("아이디를 입력해주시기 바랍니다");
    }
    // Enter입력, pw에 값이 없는 경우
    else if (e.key === "Enter" && !pw) {
      alert("비밀번호를 입력해주시기 바랍니다");
    }
    // Enter입력, id, pw에 값이 없는 경우
    else if (e.key === "Enter" && !id && !pw) {
      alert("아이디를 입력해주시기 바랍니다");
    }
  };

  // 로그인버튼 클릭함수
  const handleClickLoginBtn = (id, pw) => {
    //console.log(id, pw);
    // id, pw 둘다 입력되지 않은 경우
    if (!id && !pw) {
      alert("아이디를 입력해주시기 바랍니다");
    }
    // pw만 입력되지 않은 경우
    else if (id && !pw) {
      alert("비밀번호를 입력해주시기 바랍니다");
    }
    // id만 입력되지 않은 경우
    else if (!id && pw) {
      alert("아이디를 입력해주시기 바랍니다");
    }
    // 모두 입력된 경우
    else {
      if (serviceMode === "scan") {
        UserRefetch();
      } else if (serviceMode === "csscan") {
        CSUserRefetch();
      } else if (serviceMode === "capa") {
        CapaUserRefetch();
      }
    }
  };

  useEffect(() => {
    // scan 일 경우
    if (serviceMode === "scan") {
      // 아이디가 올바르지 않는 경우
      if (UserData && UserData.length === 0) {
        alert("아이디를 확인해주시기 바랍니다");
      }
      // 아이디가 올바른 경우
      else if (UserData && UserData.length > 0) {
        if (UserData && UserData[0].pw === pw) {
          setUserId(id);
          navigate("/m/common/analysis");
          setUserData(UserData);

          // login 성공
          // console.log(UserData);
        }
        // 비밀번호 불일치
        else {
          alert("비밀번호를 확인해주시기 바랍니다");
        }
      }
    } else if (serviceMode === "csscan") {
      // 아이디가 올바르지 않는 경우
      if (CSUserData && CSUserData.length === 0) {
        alert("아이디를 확인해주시기 바랍니다");
      }
      // 아이디가 올바른 경우
      else if (CSUserData && CSUserData.length > 0) {
        if (CSUserData && CSUserData[0].pw === pw) {
          // login 성공
          // console.log(UserData);
          setUserId(id);
          navigate("/m/common/analysis");
          setUserData(CSUserData);
        }
        // 비밀번호 불일치
        else {
          alert("비밀번호를 확인해주시기 바랍니다");
        }
      }
    } else if (serviceMode === "capa") {
      if (CapaUserData && CapaUserData.length === 0) {
        alert("아이디를 확인해주시기 바랍니다");
      } else if (CapaUserData && CapaUserData.length > 0) {
        if (CapaUserData && CapaUserData[0].pw === pw) {
          setUserId(id);
          navigate("/capacity");
          setUserData(CapaUserData);
        } else {
          alert("비밀번호를 확인해주시기 바랍니다");
        }
      }
    }
  }, [isUserFetching, isCSUserFetching, isCapaUserFetching]);

  useEffect(() => {
    if (window.location.href.toLowerCase().indexOf("csscan") > -1 && window.location.href.toLowerCase().indexOf("capa") === -1) {
      setServiceMode("csscan");
    } else if (window.location.href.toLowerCase().indexOf("capa") > -1 && window.location.href.toLowerCase().indexOf("csscan") === -1) {
      setServiceMode("capa");
    } else if (window.location.href.toLowerCase().indexOf("csscan") === -(-1) && window.location.href.toLowerCase().indexOf("capa") === -1) {
      setServiceMode("scan");
    }

    if (serviceMode === "scan") {
      setStPeriod(null);
      setEdPeriod(null);
    } else if (serviceMode === "capa") {
      setStPeriod(null);
      setEdPeriod(null);
    }

    // 자동로그인
    if (urlParams.get("id") && urlParams.get("pw")) {
      setId(urlParams.get("id"));
      setPw(urlParams.get("pw"));

      handleClickLoginBtn(urlParams.get("id"), urlParams.get("pw"));
    } else if (urlParams.get("id") && !urlParams.get("pw")) {
      setId(urlParams.get("id"));

      alert("비밀번호를 입력해주시기 바랍니다");
    } else if (!urlParams.get("id") && urlParams.get("pw")) {
      alert("아이디를 입력해주시기 바랍니다");
    }
  }, []);

  return (
    <>
      <div className="fixed flex h-full w-screen flex-col items-center justify-between bg-loginBgColor pt-52">
        <div className="flex h-fit w-full flex-col items-center justify-start">
          {/* logo */}
          {serviceMode === "scan" ? (
            <img
              src="https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/image/scan_logo.png"
              alt="scan_logo"
              className="mb-14 h-auto w-[45%]"
            />
          ) : (
            <img
              src="https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/image/csscan_logo.png"
              alt="scan_logo"
              className="mb-14 h-auto w-[55%]"
            />
          )}

          {/* 아이디 */}
          <div className="mb-4 flex h-fit w-[80%] flex-row items-center justify-start border-b border-orangeColor bg-white p-5">
            <img src="image/login_id.png" alt="login_id" className="mr-6 h-auto w-[15px]" />
            <input
              type="text"
              placeholder="아이디"
              value={id}
              onChange={handleChangeId}
              onKeyDown={handleIdPressEnter}
              className="mt-1 h-fit w-full text-left font-SDGothic text-[14px] text-black/80 outline-none"
            />
          </div>
          {/* 비밀번호 */}
          <div className="mb-4 flex h-fit w-[80%] flex-row items-center justify-start bg-white p-5">
            <img src="image/login_pw.png" alt="login_id" className="mr-6 h-auto w-[15px]" />
            <input
              type="password"
              placeholder="비밀번호"
              value={pw}
              //autocomplete="one-time-code"
              onChange={handleChangePw}
              onKeyDown={handlePwPressEnter}
              className="pw mt-1 h-fit w-full text-left font-SDGothic text-[14px] text-black/80 outline-none"
            />
          </div>
          {/* 로그인 btn */}
          <button
            onClick={() => handleClickLoginBtn(id, pw)}
            className="flex h-fit w-[80%] flex-row items-center justify-center bg-loginBtnColor p-5 py-7 font-SDGothic text-[14px] text-white"
          >
            로그인
          </button>
        </div>
        <div className="flex h-fit w-full flex-col items-center justify-center">
          {/* bgImg */}
          <img src="image/login_bg.png" alt="login_bg" className="h-auto w-[85%]" />
          {/* footer */}
          <div className="flex h-fit w-full flex-col items-center justify-center bg-loginBlueColor p-2 font-SDGothic text-[10px] text-white">
            Copyright 2023 ELIO&COMPANY All Rights Reserved.
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
