/**
 * 작성자: KYD
 * 기능:
 * 프로세스 설명: 프로세스 복잡시 노션링크 첨부권장
 */
import axios from "axios";
import { useEffect, useState } from "react";
import { useHspOptState, useHspValState, usePrjOptState, usePrjValState, useYearOptState, useYearValState } from "../../../store/mobile/store";

const CapacityList = () => {
  //SECTION HOOK호출 영역
  const newpPalette = [
    "linear-gradient(to right, #D8D8D8, #C7C7C7)", // 0-49%
    "linear-gradient(to right, #FECB74, #FEA917)", // 50-74%
    "linear-gradient(to right, #79CCAA, #22B979)", // 75-99%
    "linear-gradient(to right, #38B7EE, #0A8CC4)", // 100%+
  ];
  const newpPaletteFontColor = ["#C7C7C7", "#FEA917", "#22B979", "#0A8CC4"];
  // 년도 리스트
  // const [yearOpt, setYearOpt] = useState([]);
  const yearOpt = useYearOptState((state) => state.yearOpt);
  const setYearOpt = useYearOptState((state) => state.setYearOpt);

  // 선택된 년도
  // const [yearVal, setYearVal] = useState([]);
  const yearVal = useYearValState((state) => state.yearVal);
  const setYearVal = useYearValState((state) => state.setYearVal);

  // 병원리스트
  // const [hspOpt, setHspOpt] = useState([]);
  const hspOpt = useHspOptState((state) => state.hspOpt);
  const setHspOpt = useHspOptState((state) => state.setHspOpt);

  // 선택된 병원
  // const [hspVal, setHspVal] = useState([]);
  const hspVal = useHspValState((state) => state.hspVal);
  const setHspVal = useHspValState((state) => state.setHspVal);

  // 프로젝트 리스트
  // const [prjOpt, setPrjOpt] = useState([]);
  const prjOpt = usePrjOptState((state) => state.prjOpt);
  const setPrjOpt = usePrjOptState((state) => state.setPrjOpt);

  // 선택된 프로젝트 리스트
  const prjVal = usePrjValState((state) => state.prjVal);
  const setPrjVal = usePrjValState((state) => state.setPrjVal);
  const [data, setData] = useState([]);
  const [ssCapaListData, setSsCapaListData] = useState([]);

  //   const { CapaListData } = useGetSuspenseCapacityList(prjVal.value || "2023998", "mem");
  useEffect(() => {
    const abc = async () => {
      console.log("prjVal.value", prjVal.value);

      if (prjVal.value) {
        axios
          .get(`/capacity/list?prj_cd=${prjVal.value}&svy_ojt=LRES`, {
            baseURL: process.env.REACT_APP_SERVER_ADDRESS,
            withCredentials: true,
          })
          .then((res) => {
            console.log("res", res);
            setSsCapaListData(res.data);
          });
      }
      // const res = await getCapacityList(prjVal.value || "2023998", "mem");
      // const responseData = res.data;
      // setData(responseData);
      // console.log("res:" + res);
    };
    abc();
  }, [prjVal.value]);

  console.log("data", data);

  //!SECTION HOOK호출 영역

  //SECTION 상태값 영역

  //!SECTION 상태값 영역

  //SECTION 메서드 영역

  //!SECTION 메서드 영역

  if (ssCapaListData.length === 0) {
    return <div> Capacity현황 데이터가 없습니다.</div>;
  }

  return (
    <div className="mt-4">
      {ssCapaListData.length > 0 &&
        ssCapaListData[0].lists[0]?.mid !== null &&
        ssCapaListData.map((item, index) => (
          <div key={index} className="h-auto w-full rounded-md ">
            <div className=" h-[auto] px-2 py-4 fold:col-span-1 tablet:col-span-1 desktop:col-span-2">
              <div className="mb-4 flex h-full w-full items-center">
                <div className="h-[1.2rem] w-[1.2rem] min-w-[1.2rem] bg-orange2Color"></div>
                <div className="text-[20px] font-extrabold">&nbsp;{item.lists[0]?.mid}</div>
              </div>
              <div className="flex h-full w-full flex-wrap items-center gap-2 border-[1px] border-capacityLineColor bg-white">
                {item.lists.map((list, index) => (
                  <div key={index} className="flex h-[7rem] w-[calc(50%-0.25rem)] items-center justify-center text-[20px]">
                    <div className={`flex h-[4rem] w-full flex-wrap items-center justify-between px-[20px] ${index % 2 === 0 ? "border-r-2" : ""}`}>
                      <div className="flex h-[2rem] w-fit items-center justify-center text-[20px] fold:text-[16px]">{list.comn_NM}</div>
                      <div className="flex h-[2rem] w-fit items-center justify-center text-[18px] font-medium fold:text-[14px]">
                        <span
                          style={{
                            color:
                              newpPaletteFontColor[
                                list.limit_CNT === null || list.limit_CNT === undefined || list.limit_CNT === "" || list.limit_CNT === "0"
                                  ? 0
                                  : (list.now_CNT / list.limit_CNT) * 100 >= 100
                                  ? 3
                                  : (list.now_CNT / list.limit_CNT) * 100 >= 75
                                  ? 2
                                  : (list.now_CNT / list.limit_CNT) * 100 >= 50
                                  ? 1
                                  : 0
                              ],
                          }}
                        >
                          {list.now_CNT}&nbsp;
                        </span>
                        / {list.limit_CNT === null || list.limit_CNT === undefined || list.limit_CNT === "" ? "N" : list.limit_CNT} 명
                      </div>
                      <div className="relative flex h-[1rem] w-full items-center justify-center bg-capacityPercentBgColor text-[18px] font-medium">
                        <div
                          className={`absolute left-0 top-0 h-[1rem] w-full bg-capacityGradientYellow`}
                          style={{
                            width: `${
                              list.limit_CNT === null || list.limit_CNT === undefined || list.limit_CNT === "" || list.limit_CNT === "0"
                                ? 0
                                : Math.min(Math.floor((list.now_CNT / list.limit_CNT) * 100), 100)
                            }%`,
                            backgroundImage:
                              newpPalette[
                                list.limit_CNT === null || list.limit_CNT === undefined || list.limit_CNT === "" || list.limit_CNT === "0"
                                  ? 0
                                  : (list.now_CNT / list.limit_CNT) * 100 >= 100
                                  ? 3
                                  : (list.now_CNT / list.limit_CNT) * 100 >= 75
                                  ? 2
                                  : (list.now_CNT / list.limit_CNT) * 100 >= 50
                                  ? 1
                                  : 0
                              ],
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default CapacityList;
