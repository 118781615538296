import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/pc/Loading";
import { useCapaUser } from "../../hooks/User/useCapaUser";
import { useCSUser } from "../../hooks/User/useCSUser";
import { useUser } from "../../hooks/User/useUser";
import { usePeriodState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const LoginPage = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const LoginId = useStore((state) => state.LoginId);
  const setLoginId = useStore((state) => state.setLoginId);
  const Mode = useStore((state) => state.Mode);
  const setMode = useStore((state) => state.setMode);
  const Year = useStore((state) => state.Year);
  const HspCd = useStore((state) => state.HspCd);
  const HspNm = useStore((state) => state.HspNm);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const AnalysisType = useStore((state) => state.AnalysisType);
  const Pick = useStore((state) => state.Pick);
  const setYear = useStore((state) => state.setYear);
  const setHspCd = useStore((state) => state.setHspCd);
  const setHspNm = useStore((state) => state.setHspNm);
  const setPrjCd = useStore((state) => state.setPrjCd);
  const setSvyOjt = useStore((state) => state.setSvyOjt);
  const setPick = useStore((state) => state.setPick);
  const setAnalysisType = useStore((state) => state.setAnalysisType);
  // const [searchParams, setSearchParams] = useSearchParams();

  const url = window.location;

  const urlParams = new URLSearchParams(url.search);

  const setStPeriod = usePeriodState((state) => state.setStPeriod);
  const setEdPeriod = usePeriodState((state) => state.setEdPeriod);

  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [pw, setPw] = useState("");
  const [next, setNext] = useState(false);
  const [addr, setAddr] = useState(window.location.href.toLowerCase());

  console.log(addr.indexOf("csscan"), "addr");

  useEffect(() => {
    setMode("");
    setYear("");
    setHspCd("");
    setHspNm("");
    setPrjCd("");
    setSvyOjt("");
    setLoginId("");
    setAnalysisType("");
    setPick({
      menu_cate: "",
      menu_cate2: "",
      qst_cd: "",
      deep_ana_cd: "",
      crss_qst_cd: "",
      sort_type: "",
      cnt_yn: "",
      grp_dvs: "",
    });

    if (urlParams.get("id") && urlParams.get("pw")) {
      setId(urlParams.get("id"));
      setPw(urlParams.get("pw"));

      loginCheck(urlParams.get("id"), urlParams.get("pw"));
    } else if (urlParams.get("id") && !urlParams.get("pw")) {
      setId(urlParams.get("id"));

      alert("비밀번호를 입력해주시기 바랍니다");
    } else if (!urlParams.get("id") && urlParams.get("pw")) {
      alert("아이디를 입력해주시기 바랍니다");
    }
  }, []);

  const { isUserLoading, isUserError, UserData, isUserSuccess, UserRefetch } = useUser(id ? id : urlParams.get("id"));
  const { isCSUserLoading, isCSUserError, CSUserData, isCSUserSuccess, CSUserRefetch } = useCSUser(id ? id : urlParams.get("id"));
  const { isCapaUserLoading, isCapaUserError, CapaUserData, isCapaUserSuccess, CapaUserRefetch } = useCapaUser(id ? id : urlParams.get("id"));

  const loginCheck = (id, pw) => {
    var vTrue = false;
    if (id === "") {
      alert("아이디를 입력해주시기 바랍니다");
    } else if (id !== "" && pw === "") {
      alert("비밀번호를 입력해주시기 바랍니다");
    } else {
      vTrue = true;
    }

    if (vTrue) {
      setNext(true);
      // 주석해제
      if (addr.indexOf("capa") === -1 && addr.indexOf("csscan") === -1) {
        setStPeriod(null);
        setEdPeriod(null);
        UserRefetch();
      } else if (addr.indexOf("capa") > -1 && addr.indexOf("csscan") === -1) {
        CapaUserRefetch();
      } else if (addr.indexOf("capa") === -1 || addr.indexOf("csscan") > -1) {
        CSUserRefetch();
      }
    }
  };

  useEffect(() => {
    // 둘다 없으면 scan 로그인
    if (addr.indexOf("csscan") === -1 && addr.indexOf("capa") === -1) {
      if (isUserSuccess && UserData && UserData.length > 0 && UserData[0] && pw) {
        if (UserData[0].pw === pw) {
          // capacity 계정에 해당하는 로그인일 경우(SCAN의 SVY_OJT값이 MEM이면서 CAPACITY 계정인 경우)
          setAnalysisType("scan");
          setLoginId(id);
          setNext(false);
          setMode("scan");
          navigate("/Total");
        } else {
          setNext(false);
          alert("비밀번호를 확인해주시기 바랍니다");
        }
      } else {
        setNext(false);
      }
    }
    // 캐파로그인
    else if (addr.indexOf("capa") > -1 && addr.indexOf("csscan") === -1) {
      if (isCapaUserSuccess && CapaUserData && CapaUserData.length > 0 && CapaUserData[0] && pw && addr.indexOf("capa") > -1) {
        if (CapaUserData[0].pw === pw) {
          setAnalysisType("capa");
          setLoginId(id);
          setNext(false);
          setMode("capacity");
          navigate("/capacity");
        } else {
          setNext(false);
          alert("비밀번호를 확인해주시기 바랍니다");
        }
      } else {
        setNext(false);
      }
    }
    // csscan 로그인
    else if (addr.indexOf("capa") === -1 && addr.indexOf("csscan") > -1) {
      if (isCSUserSuccess && CSUserData && CSUserData.length > 0 && CSUserData[0] && pw && addr.indexOf("csscan") > -1) {
        if (CSUserData[0].pw === pw) {
          setAnalysisType("csscan");
          setLoginId(id);
          setNext(false);
          setMode("scan");
          navigate("/Summary");
        } else {
          setNext(false);
          alert("비밀번호를 확인해주시기 바랍니다");
        }
      } else {
        setNext(false);
      }
    }
  }, [isUserSuccess, isCSUserLoading, UserData, CSUserData, next]);

  useEffect(() => {
    if (Mode === "scan") {
      navigate("/Total");
    }
  }, [Mode]);

  const checkEnterId = (event) => {
    if (event.key === "Enter") {
      document.getElementsByClassName("pw")[0].focus();
    }
  };

  const checkEnterPw = (event) => {
    if (event.key === "Enter") {
      loginCheck(id, pw);
    }
  };

  return (
    <div className="h-[76.8rem] w-[127.8rem] bg-[#E3F5FF]">
      {loading ? <Loading /> : null}
      <div
        className={
          addr.indexOf("csscan") === -1
            ? `absolute left-[calc(50%-12.4rem)] top-[15.6rem] z-10 h-[5.8rem] w-[24.8rem] bg-scan bg-[length:auto_100%] bg-center bg-no-repeat`
            : `absolute left-[calc(50%-16.4rem)] top-[15.6rem] z-10 h-[5.8rem] w-[32.8rem] bg-csscan bg-[length:auto_100%] bg-center bg-no-repeat`
        }
      ></div>
      <div className="absolute left-[calc(50%-16.5rem)] top-[25rem] z-10 h-[4.8rem] w-[33.0rem] bg-whiteColor">
        <div className="z-10 float-left ml-[1.9rem] mt-[1.4rem] h-[1.8rem] w-[1.6rem] bg-id bg-[length:100%_100%] bg-center bg-no-repeat"></div>
        <div className="z-10 float-left ml-[2.3rem] mt-[0.8rem] h-[3.0rem] w-[calc(100%-7.7rem)]">
          <input
            type="text"
            className="id w-full text-[1.8rem] leading-[3.0rem]"
            placeholder="아이디"
            value={id}
            onChange={(e) => {
              setId(e.target.value);
            }}
            onKeyDown={checkEnterId}
          />
        </div>
      </div>
      <div className="absolute left-[calc(50%-16.5rem)] top-[30.8rem] z-10 h-[4.8rem] w-[33.0rem] bg-whiteColor">
        <div className="z-10 float-left ml-[1.9rem] mt-[1.4rem] h-[1.8rem] w-[1.6rem] bg-pw bg-[length:100%_100%] bg-center bg-no-repeat"></div>
        <div className="z-10 float-left ml-[2.3rem] mt-[0.8rem] h-[3.0rem] w-[calc(100%-7.7rem)]">
          <input
            type="password"
            className="pw w-full text-[1.8rem] leading-[3.0rem]"
            placeholder="비밀번호"
            //autocomplete="one-time-code"
            onChange={(e) => {
              setPw(e.target.value);
            }}
            onKeyDown={checkEnterPw}
          />
        </div>
      </div>
      <div
        className="absolute bottom-[50.0rem] left-[calc(50%-16.5rem)] top-[36.6rem] z-10 h-[4.8rem] w-[33.0rem] cursor-pointer bg-orangeColor text-center text-[1.8rem] leading-[4.8rem] text-whiteColor"
        onClick={() => {
          loginCheck(id, pw);
        }}
      >
        로그인
      </div>
      <div className="absolute left-[calc(50%-27.55rem)] top-[34.7rem] h-[41.6rem] w-[55.1rem] bg-login bg-[length:100%_100%] bg-center bg-no-repeat"></div>
      <div className="absolute top-[76.3rem] z-10 h-[3.2rem] w-[127.8rem] bg-loginBlueColor text-center text-[1.4rem] leading-[3.2rem] text-whiteColor">
        Copyright 2023 ELIO&COMPANY All Rights Reserved.
      </div>
    </div>
  );
};

export default LoginPage;
