const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <>
      {error.message}에러가 발생했습니다!
      <div onClick={resetErrorBoundary}>API 재호출</div>
    </>
  );
};

export default ErrorFallback;
