const Refresh = () => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.64062 9.40527C3.38493 12.0634 5.81969 14 8.70859 14C11.5723 14 13.9944 12.0887 14.7639 9.4559"
        stroke="#000000"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path d="M1 11.3408L2.19845 8.36621L5.16305 9.56868" stroke="#000000" stroke-miterlimit="10" stroke-linecap="round" />
      <path
        d="M14.3714 5.59471C13.6144 2.94927 11.1797 1 8.3034 1C5.43972 1 3.01758 2.9113 2.24805 5.54408"
        stroke="#000000"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path d="M16 3.65918L14.8016 6.63372L11.8496 5.43125" stroke="#000000" stroke-miterlimit="10" stroke-linecap="round" />
    </svg>
  );
};

export default Refresh;
