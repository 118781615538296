import "moment/locale/ko";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { HiOutlineX } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  useHspOptState,
  useHspValState,
  useMenuState,
  usePeriodState,
  usePreventRequestFilterState,
  usePrjOptState,
  usePrjValState,
  useQstCdState,
  useQstState,
  useQstSvyOjtState,
  useServiceModeState,
  useUserDataState,
  useYearOptState,
  useYearValState,
} from "../../../store/mobile/store";
import RefreshIcon from "../../../svg_icon/refresh";

const CapaFilter = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  // 기간
  const startDate = usePeriodState((state) => state.stPeriod);
  const setStartDate = usePeriodState((state) => state.setStPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const setEndDate = usePeriodState((state) => state.setEdPeriod);

  // service mode
  const serviceMode = useServiceModeState((state) => state.serviceMode);

  const [visibleSt, setVisibleSt] = useState(false);
  const [visibleEd, setVisibleEd] = useState(false);

  // userData
  const userData = useUserDataState((state) => state.userData);

  // 년도 리스트
  // const [yearOpt, setYearOpt] = useState([]);
  const yearOpt = useYearOptState((state) => state.yearOpt);
  const setYearOpt = useYearOptState((state) => state.setYearOpt);

  // 선택된 년도
  // const [yearVal, setYearVal] = useState([]);
  const yearVal = useYearValState((state) => state.yearVal);
  const setYearVal = useYearValState((state) => state.setYearVal);

  // 병원리스트
  // const [hspOpt, setHspOpt] = useState([]);
  const hspOpt = useHspOptState((state) => state.hspOpt);
  const setHspOpt = useHspOptState((state) => state.setHspOpt);

  // 선택된 병원
  // const [hspVal, setHspVal] = useState([]);
  const hspVal = useHspValState((state) => state.hspVal);
  const setHspVal = useHspValState((state) => state.setHspVal);

  // 프로젝트 리스트
  // const [prjOpt, setPrjOpt] = useState([]);
  const prjOpt = usePrjOptState((state) => state.prjOpt);
  const setPrjOpt = usePrjOptState((state) => state.setPrjOpt);

  // 선택된 프로젝트 리스트
  const prjVal = usePrjValState((state) => state.prjVal);
  const setPrjVal = usePrjValState((state) => state.setPrjVal);

  // clicked menuId
  const setMenuVal = useMenuState((state) => state.setMenuVal);

  // 선택한 문항 rn
  const setQstRnState = useQstState((state) => state.setQstState);

  // 선택한 문항 qst_Cd
  const setQstCdState = useQstCdState((state) => state.setQstCdState);

  // 선택한 문항 svy_ojt
  const setQstSvyOjtState = useQstSvyOjtState((state) => state.setQstSvyOjtState);

  // preventReqFilterState
  const setPreventReqFilterState = usePreventRequestFilterState((state) => state.setPreventReqFilterState);

  const preventReqFilterState = usePreventRequestFilterState((state) => state.preventReqFilterState);

  // year 변경 함수
  const handleChangeYear = (e) => {
    setPreventReqFilterState(false);
    setMenuVal("");
    setYearVal(e);
    getUniqueHspList(e.value);
    setQstRnState(0);
    setQstCdState("");
    setQstSvyOjtState("");

    /** csscan의 경우 활성화 */
    if (serviceMode === "csscan") {
      setStartDate(moment(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`).format("YYYY-MM-DD"));
      setEndDate(`${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`);
    }
  };

  // hsp 변경 함수
  const handleChangeHsp = (e) => {
    setPreventReqFilterState(false);
    setMenuVal("");
    setHspVal(e);
    getUniquePrjList(yearVal.value, e.value);
    setQstRnState(0);
    setQstCdState("");
    setQstSvyOjtState("");

    /** csscan의 경우 활성화 */
    if (serviceMode === "csscan") {
      setStartDate(moment(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`).format("YYYY-MM-DD"));
      setEndDate(`${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`);
    }
  };

  // prj 변경 함수
  const handleChangePrj = (e) => {
    setPreventReqFilterState(false);
    setMenuVal("");
    setPrjVal(e);
    setQstRnState(0);
    setQstCdState("");
    setQstSvyOjtState("");

    /** csscan의 경우 활성화 */
    if (serviceMode === "csscan") {
      setStartDate(moment(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`).format("YYYY-MM-DD"));
      setEndDate(`${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`);
    }
  };

  // hsp에 대한 알고리즘
  const getUniqueHspList = (year) => {
    const data = [...userData];

    // 파라미터로 받은 year에 해당하는 데이터들을 필터링 후 mapping
    let tempHspData =
      year === "A"
        ? data.map((el) => {
            return {
              value: el.hsp_CD,
              label: el.hsp_NM,
            };
          })
        : data
            .filter((el) => el.prd_YY === year)
            .map((el) => {
              return {
                value: el.hsp_CD,
                label: el.hsp_NM,
              };
            });

    // 중복 걸러내기용 리스트
    let hspCdList = tempHspData.map((el) => el.value);

    // 중복제거
    const hspCdSet = new Set(hspCdList);
    const uniqueHspCd = [...hspCdSet];

    let uniqueHspList = [];

    // 중복 걸러내기용 리스트에 해당 value가 있다면 uniqueHspList에 push하고 그 value를 제거해준다.
    tempHspData.forEach((el) => {
      let idx = uniqueHspCd.indexOf(el.value);
      if (idx !== -1) {
        uniqueHspList.push(el);
        uniqueHspCd.splice(idx, 1);
      }
    });

    // 프로젝트 리스트 요청
    getUniquePrjList(year, uniqueHspList[0].value);

    setHspOpt(uniqueHspList);
    setHspVal(uniqueHspList[0]);
  };

  // prj에 대한 알고리즘
  const getUniquePrjList = (year, hspCd) => {
    const data = [...userData];

    let tempPrjList =
      year === "A"
        ? data
            .filter((el) => el.hsp_CD === hspCd)
            .map((el) => {
              return {
                value: el.prj_CD,
                label: el.prj_NM,
              };
            })
        : data
            .filter((el) => el.prd_YY === year && el.hsp_CD === hspCd)
            .map((el) => {
              return {
                value: el.prj_CD,
                label: el.prj_NM,
              };
            });

    setPrjOpt(tempPrjList);
    setPrjVal(tempPrjList[0]);
  };

  // 컴포넌트 랜더링 시
  useEffect(() => {
    if (userData.length === 0) {
      navigate("/");
    } else if (!preventReqFilterState && hspVal.length === 0) {
      const data = [...userData];

      // year에 대한 알고리즘
      let tempYearData = data
        .map((el, idx) => {
          return el.prd_YY;
        })
        .sort((a, b) => b - a);

      const yearSet = new Set(tempYearData);
      const uniqueData = [...yearSet];

      setYearOpt([
        { value: "A", label: "전체" },
        ...uniqueData.map((el) => {
          return {
            value: el,
            label: `${el}년`,
          };
        }),
      ]);

      setYearVal(
        [
          { value: "A", label: "전체" },
          ...uniqueData.map((el) => {
            return {
              value: el,
              label: `${el}년`,
            };
          }),
        ][0]
      );

      getUniqueHspList("A");
    }
  }, []);

  // console.log(userData);

  return (
    <div className="relative flex h-fit w-full flex-col items-center justify-center">
      {/* <div className="flex h-fit w-full flex-row items-center justify-start"> */}
      <div className="flex h-fit w-full items-center justify-between border-b border-loginBlueColor px-2 py-4 font-SDGothic text-[16px] font-bold text-textColor">
        <div className="flex items-center">
          <img
            src="https://survey-file-bucket.s3.ap-northeast-2.amazonaws.com/image/SCAN.png"
            alt="logo"
            className="ml-4 w-auto font-bold fold:h-[2.5rem] tablet:h-[3rem]"
          />
          <p className="ml-4 font-bold text-capacityHeaderColor fold:text-2xl desktop:text-4xl">설문참여현황</p>
        </div>
        <div
          className="flex items-center text-2xl font-bold"
          onClick={() => {
            setModal(true);
          }}
        >
          {prjVal.label}
        </div>
        <div className=" flex h-[4rem] items-center text-lg">
          <div className="relative flex h-[4rem] cursor-pointer flex-col items-center justify-center">
            <RefreshIcon />
            <p className="text-sm">새로고침</p>
          </div>
          <div
            className="relative ml-2 flex h-[4rem] cursor-pointer flex-col items-center justify-center"
            onClick={() => {
              navigate("/");
            }}
          >
            <div className="h-[2.4rem] w-[2.4rem] bg-logout bg-[length:100%_100%] bg-center bg-no-repeat fold:h-[2rem] fold:w-[2rem]"></div>
            {/* <LogoutIcon /> */}
            <p className="text-sm">로그아웃</p>
          </div>
        </div>
      </div>

      {modal && (
        <div className="absolute left-0 top-0 z-50 flex h-screen w-screen flex-col items-center justify-center bg-black/50">
          <div className="h-[30rem] w-[90%] rounded-lg bg-white">
            <div className="flex h-[4rem] w-full items-center justify-between border-b-2 border-loginBlueColor p-6">
              <div className="text-2xl font-bold">Capacity 변경</div>
              <div onClick={() => setModal(false)} className="cursor-pointer">
                <HiOutlineX className="text-4xl text-textColor" />
              </div>
            </div>
            <div className="mt-7 flex h-fit w-full flex-row items-center justify-start">
              <div className="flex h-fit w-[30%] flex-col items-center justify-center  font-SDGothic text-[14px] font-bold text-textColor">
                설문년도
              </div>
              {/* 년도 select bar */}
              <Select
                options={yearOpt}
                isSearchable={false}
                value={yearVal}
                onChange={handleChangeYear}
                className="bg-blue8color mr-5 w-2/3 min-w-[70%] font-SDGothic text-2xl text-textColor"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#E3F5FF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            </div>
            <div className="mt-7 flex h-fit w-full flex-row items-center justify-start">
              <div className="flex  h-fit w-[30%] flex-col items-center justify-center font-SDGothic text-[14px] font-bold text-textColor">
                병원구분
              </div>
              {/* 년도 hsp select bar */}
              <Select
                options={hspOpt}
                isSearchable={false}
                value={hspVal}
                onChange={handleChangeHsp}
                className="bg-blue8color mr-5 w-2/3 min-w-[70%] font-SDGothic text-2xl text-textColor"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#E3F5FF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            </div>
            {/* </div> */}
            <div className="mt-7 flex h-fit w-full flex-row items-center justify-start">
              <div className="flex  h-fit w-[30%] flex-col items-center justify-center font-SDGothic text-[14px] font-bold text-textColor">
                설문목록
              </div>
              {/* prj select bar */}
              <Select
                options={prjOpt}
                isSearchable={false}
                value={prjVal}
                onChange={handleChangePrj}
                className="bg-blue8color mr-5 w-2/3 min-w-[70%] font-SDGothic text-2xl text-textColor"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#E3F5FF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            </div>
          </div>
        </div>
      )}
      {/* </div> */}
    </div>
  );
};

export default CapaFilter;
