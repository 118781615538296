import { useEffect, useState } from "react";
import AdminModalContainer from "../../components/pc/admin/AdminModalContainer";
import AdminUserModalComp from "../../components/pc/admin/AdminUserModalComp";
import Loading from "../../components/pc/Loading";
import useGetAdminUserSelList from "../../hooks/useGetAdminUserSelList";
import useGetAdminUserUser from "../../hooks/useGetAdminUserUser";
import useStore from "../../store/pc/store";

const AdminUserUser = () => {
  const PrjCd = useStore((state) => state.PrjCd);
  const HspCd = useStore((state) => state.HspCd);
  const { data: selList, refetch: selListRefetch, isLoading: selListLoading } = useGetAdminUserSelList(PrjCd);
  const { data: userData, isLoading: userDataLoading } = useGetAdminUserUser(PrjCd);
  const [modalState, setModalState] = useState(false);
  const [mode, setMode] = useState(false);
  const [userId, setUserId] = useState("");
  // vst, lres 클릭 상황
  const [excpMode, setExcpMode] = useState("");

  const handleClickCreate = () => {
    document.body.style.overflow = "hidden";
    setMode(true);
    setModalState(true);
  };

  const handleClickUser = (user_id) => {
    document.body.style.overflow = "hidden";
    setMode(false);
    setModalState(true);
    setUserId(user_id);
  };

  useEffect(() => {
    selListRefetch();
  }, [PrjCd]);

  if (userDataLoading || selListLoading) return <Loading />;

  const handleClickNotMem = (svy_ojt) => {
    document.body.style.overflow = "hidden";
    setModalState(true);
    setExcpMode(svy_ojt);
    setMode(false);
  };

  return (
    <>
      {modalState ? (
        <AdminModalContainer setModal={setModalState} size={"T"}>
          <AdminUserModalComp
            prj_cd={PrjCd}
            hsp_cd={HspCd}
            mode={mode}
            setMode={setMode}
            excpMode={excpMode}
            setExcpMode={setExcpMode}
            user_id={userId}
            selList={selList?.data}
            vstCnt={userData?.data.filter((el) => el.svy_OJT === "VST").length}
            lresCnt={userData?.data.filter((el) => el.svy_OJT === "LRES").length}
          />
        </AdminModalContainer>
      ) : null}

      <div className="h-full w-full flex-col items-center justify-start font-SDGothic text-[16px] font-bold text-selectTextColor/80">
        {/* create btn */}
        <div className="flex h-fit w-full flex-row items-end justify-between">
          {/* subTitle */}
          <div className="flex h-fit w-full flex-col items-start justify-start p-3">
            <div className="mt-10 h-fit w-fit text-[18px] font-bold">설문 대상자</div>
            <div className="mt-6 h-fit w-fit text-[14px] font-bold text-selectTextColor/50">설문에 참여하는 대상자 정보를 설정하세요</div>
          </div>

          <button
            type="button"
            onClick={handleClickCreate}
            className="mt-5 flex h-fit w-[100px] flex-col items-center justify-center rounded-xl bg-loginBlueColor px-5 py-6 font-SDGothic text-[16px]  font-semibold text-white hover:bg-loginBlueColor/70"
          >
            생성
          </button>
        </div>
        {/* table header */}
        <div className="mt-5 flex h-[60px] w-full flex-row items-center justify-start bg-FilterInActiveColor/40 text-[16px] font-bold text-selectTextColor">
          <div className="flex h-full w-[120px] flex-col items-center justify-center border-l border-white">직번(사번)</div>
          <div className="flex h-full w-[157px] flex-col items-center justify-center border-l border-white">설문대상</div>
          <div className="flex h-full w-[140px] flex-col items-center justify-center border-l border-white">직종</div>
          <div className="flex h-full w-[140px] flex-col items-center justify-center border-l border-white">연차그룹</div>
          <div className="flex h-full w-[150px] flex-col items-center justify-center border-l border-white">보직여부</div>
          <div className="flex h-full w-[140px] flex-col items-center justify-center border-l border-white">연령대</div>
          <div className="flex h-full w-[140px] flex-col items-center justify-center border-l border-white">성별 코드</div>
        </div>

        {userData?.data.length === 0 ? (
          <div className="flex h-fit w-full flex-col items-center justify-center p-10 text-[16px] font-bold text-selectTextColor/80">
            데이터가 존재하지 않습니다
          </div>
        ) : null}

        {/* table rows */}

        {/* MEM */}
        <div className="mb-10 flex h-fit w-full flex-col items-start justify-start">
          {userData?.data
            .filter((el) => el.svy_OJT === "MEM")
            .map((el, idx) => {
              return (
                <div
                  onClick={() => handleClickUser(el.user_ID)}
                  className="relative flex h-fit w-full cursor-pointer flex-row items-start justify-start hover:bg-FilterInActiveColor/20"
                >
                  {/* 직번(사번) */}
                  <div className="hide-scrollbar focus:bg-orangeColor/15 flex h-[60px] w-[120px] resize-none flex-col items-end justify-end border border-l-0 border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none ">
                    {el.user_ID}
                  </div>
                  {/* 설문대상 */}
                  <div className=" flex h-[60px] w-[157px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                    {selList?.data.svy_OJT.filter((el2) => el2.value === el.svy_OJT)[0]?.label}
                  </div>
                  {/* 직종코드 */}
                  <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                    {selList?.data.octy.filter((el2) => el2.value === el.octy)[0]?.label}
                  </div>
                  {/* 연차그룹코드 */}
                  <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                    {selList?.data.wrkyy.filter((el2) => el2.value === el.wrk_YY_GRP)[0]?.label}
                  </div>
                  {/* 보직여부 */}
                  <div className=" flex h-[60px] w-[150px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                    {el.assign_YN}
                  </div>
                  {/* 연령대 코드 */}
                  <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                    {selList?.data.agegrp.filter((el2) => el2.value === el.age_GRP)[0]?.label}
                  </div>
                  {/* 성별 코드 */}
                  <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                    {el.sex}
                  </div>
                </div>
              );
            })}
          {/* VST */}
          {userData?.data.filter((el) => el.svy_OJT === "VST").length ? (
            <div
              onClick={() => handleClickNotMem("VST")}
              className="relative flex h-fit w-full cursor-pointer flex-row items-start justify-start hover:bg-FilterInActiveColor/20"
            >
              {/* 직번(사번) */}
              <div className="hide-scrollbar focus:bg-orangeColor/15 flex h-[60px] w-[120px] resize-none flex-col items-end justify-end border border-l-0 border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none ">
                -
              </div>
              {/* 설문대상 */}
              <div className=" flex h-[60px] w-[157px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                {selList?.data.svy_OJT.filter((el2) => el2.value === "VST")[0]?.label}
              </div>
              {/* 직종코드 */}
              <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
              {/* 연차그룹코드 */}
              <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
              {/* 보직여부 */}
              <div className=" flex h-[60px] w-[150px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
              {/* 연령대 코드 */}
              <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
              {/* 성별 코드 */}
              <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
            </div>
          ) : null}

          {/* LRES */}
          {userData?.data.filter((el) => el.svy_OJT === "LRES").length ? (
            <div
              onClick={() => handleClickNotMem("LRES")}
              className="relative flex h-fit w-full cursor-pointer flex-row items-start justify-start hover:bg-FilterInActiveColor/20"
            >
              {/* 직번(사번) */}
              <div className="hide-scrollbar focus:bg-orangeColor/15 flex h-[60px] w-[120px] resize-none flex-col items-end justify-end border border-l-0 border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none ">
                -
              </div>
              {/* 설문대상 */}
              <div className=" flex h-[60px] w-[157px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                {selList?.data.svy_OJT.filter((el2) => el2.value === "LRES")[0]?.label}
              </div>
              {/* 직종코드 */}
              <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
              {/* 연차그룹코드 */}
              <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
              {/* 보직여부 */}
              <div className=" flex h-[60px] w-[150px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
              {/* 연령대 코드 */}
              <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
              {/* 성별 코드 */}
              <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
            </div>
          ) : null}
          {/* CS */}
          {userData?.data.filter((el) => el.svy_OJT === "CS").length ? (
            <div
              onClick={() => handleClickNotMem("CS")}
              className="relative flex h-fit w-full cursor-pointer flex-row items-start justify-start hover:bg-FilterInActiveColor/20"
            >
              {/* 직번(사번) */}
              <div className="hide-scrollbar focus:bg-orangeColor/15 flex h-[60px] w-[120px] resize-none flex-col items-end justify-end border border-l-0 border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none ">
                -
              </div>
              {/* 설문대상 */}
              <div className=" flex h-[60px] w-[157px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                {selList?.data.svy_OJT.filter((el2) => el2.value === "CS")[0]?.label}고객만족
              </div>
              {/* 직종코드 */}
              <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
              {/* 연차그룹코드 */}
              <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
              {/* 보직여부 */}
              <div className=" flex h-[60px] w-[150px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
              {/* 연령대 코드 */}
              <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
              {/* 성별 코드 */}
              <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
            </div>
          ) : null}
          {/* RS */}
          {userData?.data.filter((el) => el.svy_OJT === "RS").length ? (
            <div
              onClick={() => handleClickNotMem("RS")}
              className="relative flex h-fit w-full cursor-pointer flex-row items-start justify-start hover:bg-FilterInActiveColor/20"
            >
              {/* 직번(사번) */}
              <div className="hide-scrollbar focus:bg-orangeColor/15 flex h-[60px] w-[120px] resize-none flex-col items-end justify-end border border-l-0 border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none ">
                {/* {userData?.data
                  .filter((item) => item.svy_OJT === "RS")[0]
                  .user_ID.slice(0, 5)
                  .replace("#", "")} */}
                -
              </div>
              {/* 설문대상 */}
              <div className=" flex h-[60px] w-[157px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                {selList?.data.svy_OJT.filter((el2) => el2.value === "RS")[0]?.label}환자경험
              </div>
              {/* 직종코드 */}
              <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
              {/* 연차그룹코드 */}
              <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
              {/* 보직여부 */}
              <div className=" flex h-[60px] w-[150px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
              {/* 연령대 코드 */}
              <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
              {/* 성별 코드 */}
              <div className=" flex h-[60px] w-[140px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 bg-transparent p-7 text-right leading-snug outline-none">
                -
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default AdminUserUser;
