import { useQuery, useQueryClient } from "@tanstack/react-query";
import getAdminCapaList from "../../../api/Admin/Common/getAdminCapaList";

const useGetAdminCapaList = (prj_cd) => {
  const queryClient = useQueryClient();
  const fallback = [];

  const {
    data = fallback,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["getAdminCapaList", prj_cd],
    queryFn: () => getAdminCapaList(prj_cd),
    onSuccess: () => {
      // queryClient.invalidateQueries({
      //   queryKey: ["getTotalTargetCount", prj_cd],
      // });
      // queryClient.invalidateQueries({
      //   queryKey: ["getAdminCapaList", prj_cd],
      // });
    },
    staleTime: 0,
    gcTime: 0,
  });

  return { data, isLoading, refetch };
};

export default useGetAdminCapaList;
