import { useQuery } from "@tanstack/react-query";
import getCapacityList from "../../api/Capa/getCapacityList";

export const useGetCapacityList = (prj_cd, svy_ojt) => {
  const {
    data: CapaListData,
    isLoading,
    isError,
    refetch: CapaListRefetch,
    isFetching: isCapaListFetching,
    isSuccess: isCapaListSuccess,
  } = useQuery({
    queryKey: ["capacityList", prj_cd, svy_ojt],
    queryFn: async () => {
      const response = await getCapacityList(prj_cd, svy_ojt);
      return response;
    },
    enabled: Boolean(prj_cd) && Boolean(svy_ojt),
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return {
    CapaListData,
    isLoading,
    isError,
    isCapaListSuccess,
    isCapaListFetching,
    CapaListRefetch,
  };
};
