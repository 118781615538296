import postCapaType from "../../../api/Admin/Common/postCapaType";

import { useMutation, useQueryClient } from "@tanstack/react-query";

export const usePostCapaType = (prj_cd) => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: async (data) => await postCapaType(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getAdminCapaList", prj_cd],
      });
    },
    onError: (err) => {
      console.error(err);
    },
  });

  return { mutateAsync };
};
