import { useQuery } from "@tanstack/react-query";
import getTotalTargetCount from "../../../api/Admin/Common/getTotalTargetCount";
const useGetTotalTargetCount = (prj_cd) => {
  const fallback = [];

  const { data = fallback, isLoading } = useQuery({
    queryKey: ["getTotalTargetCount", prj_cd],
    queryFn: () => getTotalTargetCount(prj_cd),
    onSuccess: () => {
      // console.log("성공함");
    },
    staleTime: 0,
    gcTime: 0,
  });

  return { data, isLoading };
};

export default useGetTotalTargetCount;
