import AdminProjectPage from "./pages/pc/AdminProjectPage";
import CapacityPage from "./pages/pc/CapacityPage";
import CrssPage from "./pages/pc/CrssPage";
import DA01Page from "./pages/pc/DA01Page";
import DA02Page from "./pages/pc/DA02Page";
import DA03Page from "./pages/pc/DA03Page";
import DA04Page from "./pages/pc/DA04Page";
import DA05Page from "./pages/pc/DA05Page";
import DA06Page from "./pages/pc/DA06Page";
import DA07Page from "./pages/pc/DA07Page";
import DA08Page from "./pages/pc/DA08Page";
import DA09Page from "./pages/pc/DA09Page";
import DA10Page from "./pages/pc/DA10Page";
import DA11Page from "./pages/pc/DA11Page";
import DA12Page from "./pages/pc/DA12Page";
import DepPage from "./pages/pc/DepPage";
import LoginPage from "./pages/pc/LoginPage";
import NormalPage from "./pages/pc/NormalPage";
import NotFoundPage from "./pages/pc/NotFoundPage";
import RawPage from "./pages/pc/RawPage";
import ShortPage from "./pages/pc/ShortPage";
import SummaryPage from "./pages/pc/SummaryPage";
import TotalPage from "./pages/pc/TotalPage";
import TotalSvyOjtPage from "./pages/pc/TotalSvyOjtPage";

const routes = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/Admin/*",
    element: <AdminProjectPage />,
  },
  {
    path: "/Normal",
    element: <NormalPage />,
  },
  {
    path: "/Summary",
    element: <SummaryPage />,
  },
  {
    path: "/Raw",
    element: <RawPage />,
  },
  {
    path: "/Short",
    element: <ShortPage />,
  },
  {
    path: "/Crss",
    element: <CrssPage />,
  },
  {
    path: "/DA01",
    element: <DA01Page />,
  },
  {
    path: "/DA02",
    element: <DA02Page />,
  },
  {
    path: "/DA03",
    element: <DA03Page />,
  },
  {
    path: "/DA04",
    element: <DA04Page />,
  },
  {
    path: "/DA05",
    element: <DA05Page />,
  },
  {
    path: "/DA06",
    element: <DA06Page />,
  },
  {
    path: "/DA07",
    element: <DA07Page />,
  },
  {
    path: "/DA08",
    element: <DA08Page />,
  },
  {
    path: "/DA09",
    element: <DA09Page />,
  },
  {
    path: "/DA10",
    element: <DA10Page />,
  },
  {
    path: "/DA11",
    element: <DA11Page />,
  },
  {
    path: "/DA12",
    element: <DA12Page />,
  },
  {
    path: "/Dep",
    element: <DepPage />,
  },
  {
    path: "/Total",
    element: <TotalPage />,
  },
  {
    path: "/TotalSvyOjt",
    element: <TotalSvyOjtPage />,
  },
  {
    path: "/Capacity",
    element: <CapacityPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

export default routes;
