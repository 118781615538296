import { useEffect, useState } from "react";
import useGetAdminCapaList from "../../hooks/Admin/Common/useGetAdminCapaList";
import useGetTotalTargetCount from "../../hooks/Admin/Common/useGetTotalTargetCount";
// import { usePostCapaMaxPercent } from "../../hooks/Admin/Common/usePostCapaMaxPercent";
import { usePostCapaType } from "../../hooks/Admin/Common/usePostCapaType";
import { usePostCapaValue } from "../../hooks/Admin/Common/usePostCapaValue";
import { usePostTotalTargetCount } from "../../hooks/Admin/Common/usePostTotalTargetCount";
import useStore from "../../store/pc/store";

const AdminUserCount = () => {
  const PrjCd = useStore((state) => state.PrjCd);
  const [inputMode, setInputMode] = useState(false);
  const [inputTrgtMode, setInputTrgtMode] = useState(false);
  const [selectedPercent, setSelectedPercent] = useState(null);

  const { data: target_cnt, refetch: target_cnt_refetch } = useGetTotalTargetCount(PrjCd);
  const { data: capaListTmp, refetch: capaRefetch } = useGetAdminCapaList(PrjCd);
  const { mutateAsync: updateTargetTotalCount } = usePostTotalTargetCount(PrjCd);
  const { mutateAsync: setCapaMutate } = usePostCapaType(PrjCd);
  const { mutateAsync: setCapaValueMutate } = usePostCapaValue();
  // const { mutateAsync: setCapaMaxPercent } = usePostCapaMaxPercent();

  const [capaList, setCapaList] = useState([]);

  // const handleSetCapaMaxPercent = async (percentage) => {
  //   const body = {
  //     prj_cd: PrjCd,
  //     percentage: percentage,
  //   };

  //   await setCapaMaxPercent(body);
  // };

  const handleSetCapaType = async (capa_dvs, typ_cd) => {
    const body = {
      prj_cd: PrjCd,
      typ_cd: typ_cd,
      capa_dvs: capa_dvs,
    };

    await setCapaMutate(body);
  };

  /**
   * 총 인원수 자동 저장
   * @param {*} e
   */
  const handleBlurTrgtCnt = async (e) => {
    const body = {
      prj_cd: PrjCd,
      trgt_cnt: e.target.value,
    };

    await updateTargetTotalCount(body);
    setInputTrgtMode(false);
  };

  /**
   * 백분율 변경시 백분율, 인원 자동 저장장
   * @param {*} typ_cd
   * @param {*} typ_detail
   * @param {*} percent
   */
  const handleBlurCapaValue = async (typ_cd, typ_detail, percent) => {
    // 같은 분류의 백분율 합 구하기
    const sumLimitPercent = capaList
      .filter((item) => item.typ_cd === typ_cd)
      .reduce((sum, item) => {
        return sum + item.typ_details.reduce((detailSum, detail) => detailSum + Number(detail.limit_percent || 0), 0);
      }, 0);

    if (sumLimitPercent > 100) {
      alert("백분율의 합은 100을 초과할 수 없습니다.");
      const newCapaList = [...capaList];
      newCapaList.forEach((item) => {
        if (item.typ_cd === typ_cd) {
          item.typ_details.forEach((detail) => {
            if (detail.code === typ_detail) {
              detail.limit_percent = 0;
            }
          });
        }
      });
      setCapaList(newCapaList);
      return;
    }

    const body = {
      prj_cd: PrjCd,
      typ_cd: typ_cd,
      typ_detail: typ_detail,
      limit_percent: percent === null || percent.trim() === "" ? null : Number(percent),
      limit_cnt: percent === null || percent.trim() === "" ? null : calculateNewLimitCnt(percent), // Ensure the result is a number
    };

    await setCapaValueMutate(body);
  };

  /**
   * 백분율 변경시 인원수 자동 계산산
   * @param {*} e
   * @param {*} idx
   * @param {*} idxDetail
   * @returns
   */
  const handleLimitPercentChange = (e, idx, idxDetail) => {
    if (target_cnt === null || target_cnt === "") return;
    const newCapaList = [...capaList];
    newCapaList[idx].typ_details[idxDetail].limit_percent = e.target.value === "" || e.target.value === null ? null : e.target.value;
    newCapaList[idx].typ_details[idxDetail].limit_cnt =
      e.target.value === "" || e.target.value === null ? null : calculateNewLimitCnt(e.target.value); // Assuming you have a function to calculate the new limit count
    setCapaList(newCapaList);
  };

  /**
   * 총인원 기반으로 % 구하기
   * @param {*} limitPercent
   * @returns
   */
  const calculateNewLimitCnt = (limitPercent) => {
    if (target_cnt === null || target_cnt === "") return null;
    const limitCnt = Math.ceil((target_cnt * limitPercent) / 100);
    return limitCnt;
  };

  /**
   * 체크박스 active
   * @param {*} el
   */
  const handleCheckboxChange = (el) => {
    if (el.chk === "Y") {
      if (window.confirm("진행시 지정한 %가 삭제됩니다.\n진행하시겠습니까?")) {
        el.chk = "N";
        handleSetCapaType("D", el.typ_cd);
      }
    } else {
      el.chk = "Y";
      handleSetCapaType("I", el.typ_cd);
    }
    setCapaList([...capaList]);
  };

  /**
   * Radio 버튼 변경시 MaxPercentage 변경
   * @param {*} e
   */
  const handleRadioChange = async (e) => {
    // await handleSetCapaMaxPercent(e.target.value);
    setSelectedPercent(e.target.value);
  };

  useEffect(() => {
    capaRefetch();
  }, [PrjCd]);

  useEffect(() => {
    setCapaList(capaListTmp);
  }, [capaListTmp]);

  /**
   * 대분류 동적 높이
   * @param {*} detailsLength
   * @returns
   */
  const calculateHeight = (detailsLength) => {
    return detailsLength * 61; // Assuming each detail component has a height of 60px
  };

  return (
    <div className="h-full w-full flex-col items-center justify-start font-SDGothic text-[16px] font-bold text-selectTextColor/80">
      {/* subTitle */}
      <div className="flex h-fit w-full flex-col items-start justify-start p-3">
        <div className="mt-10 flex w-full items-center justify-between">
          <div className="flex items-center">
            <div
              className="h-fit w-fit text-[18px] font-bold"
              onClick={() => {
                console.log(target_cnt);
              }}
            >
              인원수
            </div>
            <input
              type="number"
              value={inputTrgtMode ? null : target_cnt}
              onChange={() => {}}
              onClick={() => setInputTrgtMode(true)}
              onBlur={(e) => handleBlurTrgtCnt(e)}
              placeholder="총 인원"
              className="ml-4 h-[30px] w-[100px] border border-gray-300 p-2 text-center"
            />
          </div>

          {/* <div className="felx-row flex h-fit items-center justify-between">
            <div className="flex h-fit w-fit flex-row items-center justify-center">
              <input
                type="radio"
                checked={selectedPercent === "5"}
                name="5"
                id="5"
                value="5"
                className="border accent-orangeColor"
                onClick={() => {}}
                onChange={handleRadioChange}
              />
              <label htmlFor="none" className="ml-1 mr-4 font-bold">
                5%
              </label>
            </div>
            <div className="flex h-fit w-fit flex-row items-center justify-center">
              <input
                type="radio"
                checked={selectedPercent === "10"}
                name="guide_img"
                id="small"
                value="10"
                className="border accent-orangeColor"
                onClick={() => {}}
                onChange={handleRadioChange}
              />
              <label htmlFor="small" className="ml-1 mr-4 font-bold">
                10%
              </label>
            </div>
            <div className="flex h-fit w-fit flex-row items-center justify-center">
              <input
                type="radio"
                checked={selectedPercent === "15"}
                name="15"
                id="15"
                value="15"
                className="border accent-orangeColor"
                onClick={() => {}}
                onChange={handleRadioChange}
              />
              <label htmlFor="medium" className="ml-1 mr-4 font-bold">
                15%
              </label>
            </div>
            <div className="flex h-fit w-fit flex-row items-center justify-center">
              <input
                type="radio"
                checked={selectedPercent === "20"}
                name="20"
                id="20"
                value="20"
                className="border accent-orangeColor"
                onClick={() => {}}
                onChange={handleRadioChange}
              />
              <label htmlFor="large" className="ml-1 font-bold">
                20%
              </label>
            </div>
          </div> */}
        </div>
        <div className="flex w-full items-center justify-between">
          <div
            className={`mt-3 h-fit w-fit text-[14px] font-bold ${
              target_cnt === "" || target_cnt === null ? "text-red-500" : "text-selectTextColor/50"
            }`}
          >
            {target_cnt === "" || target_cnt === null ? "총 인원을 지정해야 % 입력이 가능합니다" : "해당 제한 인원수를 지정해주세요"}
          </div>
          {/* <div className={`mt-3 h-fit w-fit text-[14px] font-bold text-selectTextColor/50`}>추가 모집본 %를 선택해주세요.</div> */}
        </div>
      </div>

      {/* table header */}
      <div className="mt-5 flex h-[60px] w-full flex-row items-center justify-start bg-FilterInActiveColor/40 text-[16px] font-bold text-selectTextColor">
        <div className="flex h-full w-[60px] flex-col items-center justify-center border-l border-white">체크</div>
        <div className="flex h-full w-[231px] flex-col items-center justify-center border-l border-white">사전정보</div>
        <div className="flex h-full w-[231px] flex-col items-center justify-center border-l border-white">분류</div>
        <div className="flex h-full w-[231px] flex-col items-center justify-center border-l border-white">%</div>
        <div className="flex h-full w-[231px] flex-col items-center justify-center border-l border-white">인원수</div>
      </div>

      {/* {data?.data.length === 0 ? (
        <div className="flex h-fit w-full flex-col items-center justify-center">
          <div className="flex h-fit w-full flex-col items-center justify-center p-10 text-[16px] font-bold text-selectTextColor/80">
            사전정보에서 필요한 사전정보를 선택해주세요. (연령대, 성별, 지역, 기타 등)
          </div>
        </div>
      ) : null} */}

      {/* table rows */}
      <div className="mb-10 flex h-fit w-full flex-col items-start justify-start">
        {capaList?.map((el, idx) => {
          const rowHeight = calculateHeight(el.typ_details.length);
          return (
            <div key={idx} className={`flex h-fit w-full flex-row items-start justify-start ${el.chk === "N" ? "bg-gray-100" : ""}`}>
              <div
                className="flex h-full min-h-[60px] w-[60px] flex-row items-start justify-end border-b border-FilterInActiveColor/40 p-7 leading-snug"
                style={{ height: `${rowHeight - 2}px`, width: "60px", boxSizing: "border-box" }}
              >
                <input type="checkbox" checked={el.chk === "Y"} onChange={() => handleCheckboxChange(el)} />
              </div>
              <div
                className="relative h-full w-[231px] flex-row items-start justify-start border-b border-l border-FilterInActiveColor/40"
                style={{ boxSizing: "border-box" }}
              >
                <div
                  className="flex h-full min-h-[60px] w-[231px] flex-col items-start justify-start border-l border-FilterInActiveColor/40 p-3 leading-snug outline-none"
                  style={{ height: `${rowHeight - 2}px`, width: "231px", boxSizing: "border-box" }}
                >
                  {el.typ_nm}
                </div>
              </div>
              <div className="box-border flex h-fit w-[693px] flex-col items-start justify-start">
                {el.typ_details?.map((detailEl, idxDetail) => {
                  return (
                    <div
                      key={idxDetail}
                      className={`relative box-border flex h-fit w-full resize-none flex-row items-center justify-start border-b border-FilterInActiveColor/40 outline-none `}
                      style={{ boxSizing: "border-box" }}
                    >
                      <div
                        className="hide-scrollbar box-border flex h-full min-h-[60px] w-[231px] resize-none flex-col items-start justify-start border-l border-FilterInActiveColor/40 p-3 leading-snug outline-none"
                        style={{ boxSizing: "border-box" }}
                      >
                        {detailEl.name}
                      </div>
                      <div
                        className={`hide-scrollbar flex-roe box-border flex h-full min-h-[60px] w-[231px] resize-none items-center justify-start border-l border-FilterInActiveColor/40 p-3 leading-snug outline-none ${
                          el.chk === "Y" ? "hover:bg-FilterInActiveColor/20" : ""
                        }`}
                        style={{ boxSizing: "border-box" }}
                      >
                        <input
                          type="number"
                          value={detailEl.limit_percent ?? ""}
                          onChange={(e) => handleLimitPercentChange(e, idx, idxDetail)}
                          onClick={(e) => e.target.select()}
                          onBlur={(e) => handleBlurCapaValue(el.typ_cd, detailEl.code, e.target.value)}
                          className="w-[110px] border-none text-end outline-none"
                          disabled={el.chk === "N"}
                        />
                        {detailEl.limit_percent === null || detailEl.limit_percent === "" ? <></> : "%"}
                      </div>
                      <div
                        className="hide-scrollbar box-border flex h-full min-h-[60px] w-[231px] resize-none flex-col items-center justify-center border-l border-FilterInActiveColor/40 p-3 leading-snug outline-none"
                        style={{ boxSizing: "border-box" }}
                      >
                        {detailEl.limit_cnt}
                        {detailEl.limit_cnt === null || detailEl.limit_cnt === "" ? <></> : "명"}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminUserCount;
