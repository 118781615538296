import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useGetCapacityList } from "../../hooks/Capa/useGetCapacityList";
import { useCapaUser } from "../../hooks/User/useCapaUser";
import { useSvyOjtActive } from "../../hooks/useSvyOjtActive";
import { useTotal } from "../../hooks/useTotal";
import { usePeriodState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";
import RefreshIcon from "../../svg_icon/refresh";

const CapacityPage = () => {
  const navigate = useNavigate();

  const newpPalette = [
    "linear-gradient(to right, #D8D8D8, #C7C7C7)", // 0-49%
    "linear-gradient(to right, #FECB74, #FEA917)", // 50-74%
    "linear-gradient(to right, #79CCAA, #22B979)", // 75-99%
    "linear-gradient(to right, #38B7EE, #0A8CC4)", // 100%+
  ];
  const newpPaletteFontColor = ["#C7C7C7", "#FEA917", "#22B979", "#0A8CC4"];
  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const LoginId = useStore((state) => state.LoginId);
  const Mode = useStore((state) => state.Mode);
  const Year = useStore((state) => state.Year);
  const HspCd = useStore((state) => state.HspCd);
  const HspNm = useStore((state) => state.HspNm);
  const PrjCd = useStore((state) => state.PrjCd);
  const AnalysisType = useStore((state) => state.AnalysisType);
  const setSvyOjt = useStore((state) => state.setSvyOjt);
  const setMode = useStore((state) => state.setMode);
  const setYear = useStore((state) => state.setYear);
  const setHspCd = useStore((state) => state.setHspCd);
  const setHspNm = useStore((state) => state.setHspNm);
  const setPrjCd = useStore((state) => state.setPrjCd);

  const PrjCdArr = useStore((state) => state.PrjCdArr);
  const setPrjCdArr = useStore((state) => state.setPrjCdArr);
  const setQstHspSelCd = useStore((state) => state.setQstHspSelCd);
  const setPick = useStore((state) => state.setPick);
  const [YearArr, setYearArr] = useState([{}]);
  const [HspCdArr, setHspCdArr] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [SvyOjtActiveArr, setSvyOjtActiveArr] = useState([{}]);
  const [Total, setTotal] = useState("");
  const { data: CapaListData, isLoading, isError, isCapaListSuccess, isCapaListFetching, CapaListRefetch } = useGetCapacityList(PrjCd, SvyOjt);
  const [ssCapaListData, setSsCapaListData] = useState([]);
  const { isCapaUserLoading, isCapaUserError, CapaUserData, isCapaUserSuccess, CapaUserRefetch } = useCapaUser(LoginId);

  useEffect(() => {
    CapaUserRefetch();
  }, []);

  useEffect(() => {
    if (PrjCd && SvyOjt) {
      CapaListRefetch();
      axios
        .get(`/capacity/list?prj_cd=${PrjCd}&svy_ojt=${SvyOjt}`, {
          baseURL: process.env.REACT_APP_SERVER_ADDRESS,
          withCredentials: true,
        })
        .then((res) => {
          setSsCapaListData(res.data);
        });
    } else {
      setSsCapaListData([]);
    }
  }, [PrjCd, SvyOjt]);

  useEffect(() => {
    if (CapaListData) {
      setSsCapaListData(CapaListData);
    }
  }, [CapaListData]);

  useEffect(() => {
    setLoading(true);
    if (CapaUserData !== null && CapaUserData !== undefined && CapaUserData.length > 0) {
      setLoading(false);
      var vPrdYy = [];
      CapaUserData.map((dd, i) => {
        vPrdYy.push(dd.prd_YY);
      });
      vPrdYy = vPrdYy.filter((v, i) => vPrdYy.indexOf(v) === i);
      vPrdYy = vPrdYy.filter((x) => x !== null && x !== undefined && x !== "");

      var vYearJson = [{}];
      vYearJson.push({ value: "ALL", label: "전체" });
      vPrdYy.map((data, i) => {
        vYearJson.push({ value: data, label: data + "년" });
      });
      vYearJson = vYearJson.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");

      setYearArr([...vYearJson]);
    }
  }, [CapaUserData]);

  useEffect(() => {
    var vYear = "";
    if (YearArr && YearArr.length > 0 && YearArr[0]) {
      if (Year === "" || Year === null || Year === undefined) {
        vYear = YearArr[0].value;
        if (vYear !== undefined && vYear !== null && vYear !== "") {
          setYear(vYear);
        }
      }
    }
  }, [YearArr]);

  useEffect(() => {
    var vHspCd = [{}];
    if (AnalysisType === "capa") {
      if (CapaUserData !== null && CapaUserData !== undefined && CapaUserData.length > 0) {
        if (Year === "ALL") {
          CapaUserData.map((dd, i) => {
            vHspCd.push({ value: dd.hsp_CD, label: dd.hsp_NM });
          });
        } else {
          CapaUserData.filter((x) => x.prd_YY === Year).map((dd, i) => {
            vHspCd.push({ value: dd.hsp_CD, label: dd.hsp_NM });
          });
        }

        vHspCd = vHspCd.filter((item1, idx1) => {
          return (
            vHspCd.findIndex((item2, idx2) => {
              return item1.value === item2.value;
            }) === idx1
          );
        });
        vHspCd = vHspCd.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
        setHspCdArr([...vHspCd]);
      }
    }
  }, [Year, isCapaUserSuccess]);

  useEffect(() => {
    var vHspCd = "";
    if (HspCdArr !== null && HspCdArr !== undefined && HspCdArr.length > 0) {
      vHspCd = HspCdArr[0].value;
      if (vHspCd !== undefined && vHspCd !== null && vHspCd !== "") {
        setHspCd(vHspCd);
      }
    }
  }, [HspCdArr]);

  useEffect(() => {
    var vHspNm = "";
    if (CapaUserData !== null && CapaUserData !== undefined && CapaUserData.length > 0) {
      if (HspCd !== "" && HspCd !== null && HspCd !== undefined) {
        vHspNm = CapaUserData.filter((x) => x.hsp_CD === HspCd)[0].hsp_NM;
        if (vHspNm !== undefined && vHspNm !== null && vHspNm !== "") {
          setHspNm(vHspNm);
        }
      }
    } else {
      CapaUserRefetch();
    }
  }, [HspCd]);

  useEffect(() => {
    var vPrjCd = [{}];
    if (AnalysisType === "capa") {
      if (CapaUserData !== null && CapaUserData !== undefined && CapaUserData.length > 0) {
        if (Year === "ALL") {
          CapaUserData.filter((x) => x.hsp_CD === HspCd).map((dd, i) => {
            vPrjCd.push({ value: dd.prj_CD, label: dd.prj_NM });
          });
        } else {
          CapaUserData.filter((x) => x.hsp_CD === HspCd && x.prd_YY === Year).map((dd, i) => {
            vPrjCd.push({ value: dd.prj_CD, label: dd.prj_NM });
          });
        }

        vPrjCd = vPrjCd.filter((item1, idx1) => {
          return (
            vPrjCd.findIndex((item2, idx2) => {
              return item1.value === item2.value;
            }) === idx1
          );
        });

        vPrjCd = vPrjCd.filter((x) => x.value !== null && x.value !== undefined && x.value !== "").sort((a, b) => b.value - a.value);

        setPrjCdArr([...vPrjCd]);
      }
    }
  }, [HspNm, isCapaUserSuccess]);

  useEffect(() => {
    var vPrjCd = "";
    if (PrjCdArr !== null && PrjCdArr !== undefined && PrjCdArr.length > 0) {
      vPrjCd = PrjCdArr[0].value;
      if (vPrjCd !== undefined && vPrjCd !== null && vPrjCd !== "") {
        setPrjCd(vPrjCd);
      } else {
      }
    }
  }, [PrjCdArr]);

  const { isSvyOjtActiveLoading, isSvyOjtActiveError, SvyOjtActiveData, isSvyOjtActiveSuccess, SvyOjtActiveRefetch } = useSvyOjtActive(PrjCd);

  useEffect(() => {
    if (AnalysisType === "capa") {
      if (PrjCd !== null && PrjCd !== undefined && PrjCd !== "" && CapaUserData) {
        var vSelCd =
          CapaUserData.filter((x) => x.prj_CD === PrjCd) && CapaUserData.filter((x) => x.prj_CD === PrjCd).length > 0
            ? CapaUserData.filter((x) => x.prj_CD === PrjCd)[0].qst_HSP_SEL_CD
            : "";
        if (vSelCd !== undefined && vSelCd !== null && vSelCd !== "") {
          setQstHspSelCd(vSelCd);
        }
      }
    }
    setPick({
      menu_cate: "",
      menu_cate2: "",
      qst_cd: "",
      deep_ana_cd: "",
      crss_qst_cd: "",
      sort_type: "",
      cnt_yn: "",
      grp_dvs: "",
    });
    if (isSvyOjtActiveSuccess) {
      if (Mode !== "admin") {
        navigate(`/capacity`);
      }
    }
  }, [PrjCd, isSvyOjtActiveSuccess]);

  useEffect(() => {
    var vSvyOjtActive = [{}];
    if (
      PrjCd !== null &&
      PrjCd !== undefined &&
      PrjCd !== "" &&
      isSvyOjtActiveSuccess &&
      SvyOjtActiveData !== null &&
      SvyOjtActiveData !== undefined &&
      SvyOjtActiveData.length > 0
    ) {
      SvyOjtActiveData.map((data, i) => {
        vSvyOjtActive.push({ value: data.comn_CD1, label: data.comn_NM1 });
      });
      vSvyOjtActive = vSvyOjtActive.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
      setSvyOjtActiveArr([...vSvyOjtActive]);
    } else {
      SvyOjtActiveRefetch();
    }
  }, [PrjCd, isSvyOjtActiveSuccess, SvyOjtActiveData]);

  useEffect(() => {
    var vSvyOjt = "";
    if (SvyOjtActiveArr !== null && SvyOjtActiveArr !== undefined && SvyOjtActiveArr.length > 0) {
      vSvyOjt = SvyOjtActiveArr[0].value;
      if (vSvyOjt !== undefined && vSvyOjt !== null && vSvyOjt !== "") {
        setSvyOjt(vSvyOjt);
      }
    }
  }, [SvyOjtActiveArr]);

  const { isTotalLoading, isTotalError, TotalData, isTotalSuccess, TotalRefetch } = useTotal(PrjCd, SvyOjt, startDate, endDate);

  useEffect(() => {
    if (SvyOjt !== "DEP") {
      TotalRefetch();
    }
  }, [PrjCd, SvyOjt, startDate, endDate]);

  useEffect(() => {
    var vTotal = "";
    if (isTotalSuccess && TotalData !== null && TotalData !== undefined && TotalData.length > 0) {
      vTotal = TotalData[0].comn_CD1;
      if (vTotal !== undefined && vTotal !== null && vTotal !== "") {
        setTotal(vTotal);
      }
    } else {
      setTotal("");
    }
  }, [isTotalSuccess, PrjCd, SvyOjt, startDate, endDate]);

  return (
    <>
      <div className="fixed top-0 z-50 flex h-[5.6rem] w-screen items-center justify-between border-b-2 border-loginBlueColor bg-white font-SDGothic fold:px-2 desktop:mx-auto desktop:w-[127.8rem] desktop:px-2">
        <div className="flex h-full w-fit items-center justify-center gap-2">
          <div className="flex shrink-0 items-center gap-2 text-4xl font-bold text-capacityHeaderColor fold:h-[2rem] tablet:h-[3rem]">
            <img
              src="https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/image/SCAN.png"
              alt="logo"
              className="ml-4 w-auto font-bold fold:h-[2rem] tablet:h-[3rem]"
            />
            <p className="ml-4 font-bold text-capacityHeaderColor fold:text-2xl desktop:text-4xl">설문참여현황</p>
          </div>
          <div className="ml-4 shrink-0 items-center gap-3 fold:hidden tablet:flex">
            {/* fold:hidden */}
            <Select
              key={"year"}
              options={YearArr} //위에서 만든 배열을 select로 넣기
              onChange={(e) => setYear(e.value)} //값이 바뀌면 setState되게
              value={YearArr.filter((option) => {
                return option.value === Year;
              })}
              defaultValue={Year}
              isSearchable={false}
              className="desktop:w- fold:w-[3rem] tablet:w-auto"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: "#ffffff",
                  fontSize: "1.6rem",
                  borderRadius: "0",
                }),
                menu: (provided, state) => ({
                  ...provided,
                  fontSize: "1.6rem",
                }),
                indicatorSeparator: (styles) => ({
                  display: "none",
                }),
                valueContainer: (provided, state) => ({
                  height: "1.6rem",
                  lineHeight: "1.6rem",
                  padding: "0 0.8rem",
                }),
              }}
            />
            <Select
              key={"HspCd"}
              options={HspCdArr} //위에서 만든 배열을 select로 넣기
              onChange={(e) => {
                setHspCd(e.value);
              }} //값이 바뀌면 setState되게
              value={HspCdArr.filter((option) => {
                return option.value === HspCd;
              })}
              defaultValue={HspCd}
              isSearchable={false}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: "#ffffff",
                  fontSize: "1.6rem",
                  width: "26.0rem",
                  borderRadius: "0",
                }),
                menu: (provided, state) => ({
                  ...provided,
                  fontSize: "1.6rem",
                }),
                indicatorSeparator: (styles) => ({
                  display: "none",
                }),
                valueContainer: (provided, state) => ({
                  height: "1.6rem",
                  lineHeight: "1.6rem",
                  padding: "0 0.8rem",
                }),
              }}
            />

            <Select
              key={"PrjCd"}
              options={PrjCdArr} //위에서 만든 배열을 select로 넣기
              onChange={(e) => setPrjCd(e.value)} //값이 바뀌면 setState되게
              value={PrjCdArr.filter((option) => {
                return option.value === PrjCd;
              })}
              defaultValue={PrjCd}
              isSearchable={false}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: "#ffffff",
                  fontSize: "1.6rem",
                  width: "28.0rem",

                  borderRadius: "0",
                }),
                menu: (provided, state) => ({
                  ...provided,
                  fontSize: "1.6rem",
                }),
                indicatorSeparator: (styles) => ({
                  display: "none",
                }),
                valueContainer: (provided, state) => ({
                  height: "1.6rem",
                  lineHeight: "1.6rem",
                  padding: "0 0.8rem",
                  width: "22.8rem",
                }),
              }}
            />
          </div>
        </div>

        <div className="flex h-full w-fit items-center justify-center gap-2">
          <div
            onClick={() => {
              // window.location.reload();
              CapaListRefetch();
              // 이거 리페칭으로 변경
            }}
            className="flex cursor-pointer flex-col items-center justify-center"
          >
            <RefreshIcon />
            <p className="mt-1 text-xl">새로고침</p>
          </div>
          <div
            className="ml-2 flex cursor-pointer flex-col items-center justify-center"
            onClick={() => {
              navigate("/");
              setMode("");
            }}
          >
            <div className="h-[2.4rem] w-[2.4rem] bg-logout bg-[length:100%_100%] bg-center bg-no-repeat fold:h-[2rem] fold:w-[2rem]"></div>
            <p className="mt-1 text-xl">로그아웃</p>
          </div>
        </div>
      </div>
      <div className="mt-[5.6rem] grid w-screen gap-4  p-4 font-SDGothic fold:grid-cols-1 tablet:grid-cols-2 desktop:mx-auto desktop:w-[127.8rem] desktop:grid-cols-2">
        {/* {isLoading && (
          <div className="flex h-[400px] items-center justify-center text-center text-4xl fold:col-span-1 tablet:col-span-1 desktop:col-span-2">
            <Loading />
          </div>
        )} */}
        {ssCapaListData.length === 0 && (
          <div className="flex h-[400px] items-center justify-center text-center text-4xl fold:col-span-1 tablet:col-span-1 desktop:col-span-2">
            Capacity현황 데이터가 없습니다.
          </div>
        )}
        {ssCapaListData.length > 0 &&
          ssCapaListData[0].lists[0]?.mid !== null &&
          ssCapaListData.map((item, index) => (
            <div key={index} className="h-auto w-full rounded-md ">
              <div className=" h-[auto] py-4 fold:col-span-1 tablet:col-span-1 desktop:col-span-2">
                <div className="mb-4 flex h-full w-full items-center">
                  <div className="h-[1.2rem] w-[1.2rem] min-w-[1.2rem] bg-orange2Color"></div>
                  <div className="text-[20px] font-extrabold">&nbsp;{item.lists[0]?.mid}</div>
                </div>
                <div className="flex h-full w-full flex-wrap items-center gap-2 border-[1px] border-capacityLineColor bg-white">
                  {item.lists.map((list, index) => (
                    <div key={index} className="flex h-[7rem] w-[calc(50%-0.25rem)] items-center justify-center text-[20px]">
                      <div className={`flex h-[4rem] w-full flex-wrap items-center justify-between px-[20px] ${index % 2 === 0 ? "border-r-2" : ""}`}>
                        <div className="flex h-[2rem] w-fit items-center justify-center text-[20px]">{list.comn_NM}</div>
                        <div className="flex h-[2rem] w-fit items-center justify-center text-[18px] font-medium">
                          <span
                            style={{
                              color:
                                newpPaletteFontColor[
                                  list.limit_CNT === null || list.limit_CNT === undefined || list.limit_CNT === "" || list.limit_CNT === "0"
                                    ? 0
                                    : (list.now_CNT / list.limit_CNT) * 100 >= 100
                                    ? 3
                                    : (list.now_CNT / list.limit_CNT) * 100 >= 75
                                    ? 2
                                    : (list.now_CNT / list.limit_CNT) * 100 >= 50
                                    ? 1
                                    : 0
                                ],
                            }}
                          >
                            {list.now_CNT}&nbsp;
                          </span>
                          / {list.limit_CNT === null || list.limit_CNT === undefined || list.limit_CNT === "" ? "N" : list.limit_CNT} 명
                        </div>
                        <div className="relative flex h-[1rem] w-full items-center justify-center bg-capacityPercentBgColor text-[18px] font-medium">
                          <div
                            className={`absolute left-0 top-0 h-[1rem] w-full bg-capacityGradientYellow`}
                            style={{
                              width: `${
                                list.limit_CNT === null || list.limit_CNT === undefined || list.limit_CNT === "" || list.limit_CNT === "0"
                                  ? 0
                                  : Math.min(Math.floor((list.now_CNT / list.limit_CNT) * 100), 100)
                              }%`,
                              backgroundImage:
                                newpPalette[
                                  list.limit_CNT === null || list.limit_CNT === undefined || list.limit_CNT === "" || list.limit_CNT === "0"
                                    ? 0
                                    : (list.now_CNT / list.limit_CNT) * 100 >= 100
                                    ? 3
                                    : (list.now_CNT / list.limit_CNT) * 100 >= 75
                                    ? 2
                                    : (list.now_CNT / list.limit_CNT) * 100 >= 50
                                    ? 1
                                    : 0
                                ],
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default CapacityPage;
