import Scrollbars from "react-custom-scrollbars-2";
import { isMobile } from "react-device-detect";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import AdminMenu from "./components/pc/admin/AdminMenu";
import Header from "./components/pc/Header";
import ScanMenu from "./components/pc/ScanMenu";
import CapacityPage from "./pages/pc/CapacityPage";
import routes from "./routes";
import routesMobile from "./routesMoblie";
import useStore from "./store/pc/store";

function App() {
  const Mode = useStore((state) => state.Mode);

  return (
    <>
      {/* width가 764px 이상일 경우 데스크탑 라우터 적용, 이하일 경우 모바일 라우터 적용 */}
      {!isMobile ? (
        <Router>
          {Mode === "capacity" && (
            <div>
              <CapacityPage />
            </div>
          )}
          {Mode !== "" && Mode !== "capacity" && (
            <div
              className={`fixed z-50 float-left font-SDGothic ${Mode === "scan" || Mode === "csscan" ? "h-[10.4rem]" : "h-[5.6rem]"} w-[127.8rem]`}
            >
              <Header />
            </div>
          )}

          {Mode !== "capacity" && (
            <div
              className={`float-left flex h-[calc(100%-4rem)] w-full bg-loginBlueColor font-SDGothic ${
                Mode === "scan" || Mode === "csscan" ? "pt-[10.4rem]" : "h-[5.6rem]"
              }`}
            >
              {/* menu sidebar */}
              <div
                className={`fixed float-left h-[calc(100%-10.4rem)] w-[23.8rem] overflow-auto ${Mode ? "" : "hidden"} ${
                  Mode === "admin" ? "pt-[5.6rem]" : ""
                }`}
              >
                <Scrollbars
                  renderTrackVertical={({ style, ...props }) => (
                    <div
                      {...props}
                      style={{
                        ...style,
                        backgroundColor: "transparent",
                        right: "2px",
                        bottom: "2px",
                        top: "2px",
                        borderRadius: "3px",
                        width: "7px",
                      }}
                    />
                  )}
                  renderThumbVertical={({ style, ...props }) => (
                    <div
                      {...props}
                      style={{
                        ...style,
                        width: "7px",
                        borderRadius: "4px",
                        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                        backgroundColor: "#E9E9E9",
                        opacity: "60%",
                      }}
                    />
                  )}
                  autoHide
                  autoHideTimeout={1000}
                  autoHideDuration={300}
                >
                  {Mode === "scan" && <ScanMenu />}
                  {Mode === "admin" && (
                    <>
                      {/* <div className="h-screen w-full"> */}
                      <AdminMenu />
                      {/* </div> */}
                    </>
                  )}
                </Scrollbars>
              </div>

              {/* content area */}
              <div
                className={`float-left ${Mode ? "ml-[23.8rem]" : ""} bg-white ${
                  Mode === "scan" || Mode === "csscan" || Mode === "admin" ? "w-[calc(100%-23.8rem)]" : "w-full"
                } ${Mode === "admin" ? "pt-[5.6rem]" : ""}`}
              >
                <Routes>
                  {routes.map((route) => {
                    return <Route key={route.path} path={route.path} element={route.element} />;
                  })}
                </Routes>
              </div>
            </div>
          )}
        </Router>
      ) : (
        <Router>
          <Routes>
            {routesMobile.map((route) => {
              return <Route key={route.path} path={route.path} element={route.element} />;
            })}
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;
